import React from "react";
import { IAboutProps } from "./AboutProps";
import Row from "../../../../components/global/Row/Row";
import Title2 from "../../../../components/global/Title2/Title2";
import Amenities from "../../../../components/common/Amenities/Amenities";
import { Container, TitleContainer, DescContainer,  Time, Description } from "./AboutHelpers";

const About = (props: IAboutProps): JSX.Element => {
    const hotel = props.hotel;
    const themeColor = props.hotel.relationships.brand_id?.field_color;
    const locality = props.hotel?.address?.locality;
    const checkInTime = hotel.field_check_in || '3:00 PM';
    const checkOutTime = hotel.field_check_out || '11:00 AM';
    const amenities = hotel.amenities;
    let aboutText = "";
    let aboutTitle = "";
    const features = hotel.features;
    if(features && features.length) {
        const about = features.filter((f: any) => {
            const tabKey = f.tab_title?.toLowerCase().trim();
            return tabKey === "about";
        });
        aboutText = about[0] ? about[0]?.description : '';
        aboutTitle = about[0] ? about[0]?.title : `Welcome To ${locality}`;
    }

    return (
    <Container>
        <Row>
            <TitleContainer>
                <Title2 id="hotel-title">{aboutTitle}</Title2>
                <Time checkInTime={checkInTime} checkOutTime={checkOutTime}/> 
            </TitleContainer>
            <DescContainer>
                <Description text={aboutText}  themeColor={themeColor?.color}/>
                {amenities !== null && amenities.length && (
                    <div className="amenitiesRow">
                    <Amenities amenities={amenities} color={themeColor?.color}/>
                    </div>
                )}
            </DescContainer>
        </Row>
    </Container>);
};

export default About;