import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { IHotelTopAlertProps } from "./HotelTopAlertProps";
import { StyledWrapper } from "./HotelTopAlertHelpers";

const HotelTopAlert = (props: IHotelTopAlertProps) => {
    const message = props.message || null;
    const [hideBanner, setHideBanner] = useState(true);
    const msgHtmlContent = message ? message.field_message?.value: '';
    const messageDesc = message ? message.field_description : '';
    const hotelCode = props.hotelCode || '';

    const handleClose = () => {
        setHideBanner(true);
        const storeObj = {
            dismissed: true,
            message: message
        };

        sessionStorage[`${hotelCode}_hotelMessage`] = JSON.stringify(storeObj);
    };

    useEffect(() => {
        if (!message) {
            setHideBanner(true);
        } else {
            let storedObj = sessionStorage[`${hotelCode}_hotelMessage`];
            if (storedObj) {
                storedObj = JSON.parse(storedObj);
                if (storedObj.message.field_description === messageDesc || storedObj.message.field_message?.value === msgHtmlContent) {
                    !storedObj.dismissed && setHideBanner(false);
                } else {
                    setHideBanner(false);
                }
            } else {
                setHideBanner(false);
            }
        }

    }, []);

    return (
        !hideBanner &&
        <StyledWrapper>
            <Container fluid="sm" className="text-left d-flex">
                <FontAwesomeIcon icon={faInfoCircle} />
                {msgHtmlContent ? (
                        <div className="alertMessage" dangerouslySetInnerHTML={{__html: msgHtmlContent}}></div>
                    ) : (
                        <p className="alertMessage"> {messageDesc}</p>
                    )}
                <FontAwesomeIcon
                    icon={faTimes}
                    onClick={handleClose}
                />
            </Container>
        </StyledWrapper>
    );
};

export default HotelTopAlert;
