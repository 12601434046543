import * as React from "react";
import { container, styledWrapper } from "./HotelTopAlert.module.scss";

export const Container = (props: {children:JSX.Element}) => {
    return (<div className={container}>{props.children}</div>);
};
export const StyledWrapper = (props: {children:JSX.Element}) => {
    return (<div className={styledWrapper}>{props.children}</div>);
};



