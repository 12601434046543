import * as React from "react";
import { useEffect } from "react";
import Col from "../../../../components/global/Col/Col";
import { container, titleContainer, descContainer, label, divider, time, description, readMore } from "./About.module.scss";

export const Container = (props: { children: any }): JSX.Element => {
    return (<div className={container}>{props.children}</div>);
};

export const TitleContainer = (props: { children: any }): JSX.Element => {
    return (<Col className={`col4 ${titleContainer}`}>{props.children}</Col>);
};

export const DescContainer = (props: { children: any }): JSX.Element => {
    return (<Col className={`col7 ${descContainer}`}>{props.children}</Col>);
};


export const Time = (props: { checkInTime: string, checkOutTime: string }): JSX.Element => {
    return (
        <div className={time}>
            <span id={"checkin-time"} className={label}>Check-in: </span>
            {props.checkInTime}
            <span className={divider}>|</span>
            <span id={"checkout-time"} className={label}>Checkout: </span>
            {props.checkOutTime}
        </div>
    );
};

export const Description = (props: { text: string, themeColor: string }): JSX.Element => {
    const TRIMLENGTH = 375;
    const aboutText = props.text || '';
    const isDescToTrim = aboutText.length > TRIMLENGTH;
    const [isFullText, setIsFullText] = React.useState(false);
    const handleReadMore = () => {
        setIsFullText(true);
    };
    const handleReadLess = () => {
        setIsFullText(false);
    };
    useEffect(() => {
        if (isDescToTrim) {
            setIsFullText(false);
        } else {
            setIsFullText(true);
        }
    }, []);

    return (
        <>
            <div className={description}>
                <div
                    className={!isFullText ? "content" : ""}
                    dangerouslySetInnerHTML={{ __html: aboutText }} />
            </div>
            {aboutText?.length > 375 ? !isFullText ? <a
                className={readMore}
                id="about-read-more"
                onClick={handleReadMore}
                style={{
                    textDecorationColor: props.themeColor,
                }}
            >
                Read More +
            </a> : <a
                className={readMore}
                id="about-read-less"
                onClick={handleReadLess}
                style={{
                    textDecorationColor: props.themeColor,
                }}
            >
                Read Less -
            </a> : null}

        </>
    );
};
