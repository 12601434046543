import React from "react";
import ReactDOMServer from "react-dom/server";

import { IPointsOfInterestProps, TPoint } from "./PointsOfInterestProps";
import { Marker, Popup, TileLayer, Circle, FeatureGroup } from "react-leaflet";
import Leaflet from "leaflet";
import {
  ContentWrapper,
  StyledDistanceFilter,
  StyledMap,
  StyledPointList,
} from "./PointsOfInterestHelpers";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DistanceFilter from "../Search/Filters/DistanceFilter/DistanceFilter";
import { isBrowser } from "../../../utils/helpers";
import { Spinner } from "react-bootstrap";
import useMobileDetect from "../../../hooks/useMobileDetect";
import { MILES_IN_METERS } from "../../../@types/Constants";
import { popUpOverlay } from "./PointsOfInterest.module.scss";

const PointsOfInterest = (props: IPointsOfInterestProps) => {
  const circleRef = React.useRef();
  const popupMarkers: (Leaflet.Popup | null)[] = [];
  const [map, setMap] = React.useState(null);
  const [viewMore, setViewMore] = React.useState(true);
  const [pointsToShow, setPointsToShow] = React.useState([]);
  const isMobileOnly = useMobileDetect();
  const bounds: (number | string)[] = [];
  const fitToCustomLayer = () => {
    if (map && circleRef.current) {
      const layer = circleRef.current;
      map.fitBounds(layer.getBounds());
    }
  };
  const [position, setPosition] = React.useState([
    props.latitude,
    props.longitude,
  ]);

  const handleSetMarkerPosition = (event: any) => {
    const newPosition = event.target.options.position;
    setPosition(newPosition);
  };

  const handleSetListPosition = (event: any) => {
    const index = event.currentTarget.dataset.index; //event.target.dataset.index;
    map && popupMarkers[index] && popupMarkers[index].openOn(map);
  };

  const handleDistanceChange = (newDistance: any) => {
    if (props.distance && props.setDistance !== undefined) {
      props.setDistance(newDistance);
    }
  };

  const handleViewMoreLessPOI = () => {
    setViewMore(!viewMore);
  };

  const showLessPoints = () => {
    const lessPoints = props.points?.slice(0, 10);
    setPointsToShow(lessPoints);
  };

  React.useEffect(() => {
    map && bounds.length && map.fitBounds(bounds);
    if (isMobileOnly && props.points?.length > 10) {
      setViewMore(true);
      setPointsToShow(props.points);
      // showLessPoints();
    } else {
      setViewMore(false);
      setPointsToShow(props.points);
    }
  }, [props.points, isMobileOnly]);

  React.useEffect(() => {
    if (isMobileOnly && viewMore) {
      // showLessPoints();
      setPointsToShow(props.points);
    } else {
      setPointsToShow(props.points);
    }
  }, [viewMore, isMobileOnly]);

  const isPoints = props.points && props.points?.length > 0;

  if (typeof window !== "undefined") {
    let options;
    const CenterPin = new Leaflet.divIcon({
      html: ReactDOMServer.renderToString(
        <svg>
          <use xlinkHref="/svg/icon-pin-red.svg#RedPin"> </use>
        </svg>
      ),
      className: "custom-icon",
      iconSize: [36, 36],
    });
    isPoints &&
      props.points?.map((point: { coordinates: string }) => {
        bounds.push([point.coordinates.latitude, point.coordinates.longitude]);
      });
    if (isPoints) {
      options = {
        center: position,
        scrollWheelZoom: false,
        bounds: bounds,
      };
    } else {
      options = {
        zoom: 15,
        center: position,
        scrollWheelZoom: false,
      };
    }
    const furthestPOI: number =
      (pointsToShow?.length &&
        Number(
          pointsToShow[pointsToShow?.length - 1]?.distance?.split(" ")[0] || 0
        )) ||
      props.distance;
    const _distance = furthestPOI * MILES_IN_METERS; //converting distance into meters
    return isBrowser ? (
      <React.Fragment>
        <div aria-hidden={true}>
          <StyledMap
            {...options}
            onlayeradd={fitToCustomLayer}
            whenCreated={setMap}
            tabIndex={-1}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              eventHandlers={{
                tileloadstart: (event) => {
                  event?.tile?.setAttribute("loading", "lazy");
                },
              }}
            />
            <Marker
              position={[props.latitude, props.longitude]}
              icon={CenterPin}
              alt={`Pin in map`}
              keyboard={false}
            />
            <FeatureGroup ref={circleRef}>
              <Circle
                center={[props.latitude, props.longitude]}
                radius={_distance}
                fill={false}
                stroke={false}
              />
            </FeatureGroup>
            {isPoints &&
              pointsToShow?.map((point: TPoint, index: number) => {
                const coordinates = [
                  point.coordinates.latitude,
                  point.coordinates.longitude,
                ];
                const customIcon = new Leaflet.divIcon({
                  className: `poi-custom-icon`,
                  html: ReactDOMServer.renderToString(
                    <span id={index}>{index + 1} </span>
                  ),
                });
                const path = point.url?.includes("locations") ? point.url : "";
                const id = point.name?.replace(/ /g, "-");
                return (
                  <Marker
                    key={index}
                    position={coordinates}
                    icon={customIcon}
                    eventHandlers={{
                      click: handleSetMarkerPosition,
                    }}
                    alt={`Pin in map`}
                    keyboard={false}
                  >
                    <Popup
                      position={coordinates}
                      ref={(r) => {
                        popupMarkers.push(r);
                      }}
                      tabIndex={-1}
                      className={popUpOverlay}
                    >
                      <div>
                        <h6 className="mb-1 popup-title">{point.name}</h6>
                        <div className="mb-1 popup-content">
                          {point.city}, {point.state}
                        </div>
                        <div className="popup-content">
                          {`${point.rangeInMilesFromOrigin} miles`}{" "}
                          {path && (
                            <>
                              -{" "}
                              <a
                                href={path}
                                className="PoiLink"
                                target="_blank"
                                id={id}
                                data-index={id}
                                rel="noreferrer"
                              >
                                See Nearby Hotels
                              </a>
                            </>
                          )}
                        </div>
                      </div>
                    </Popup>
                  </Marker>
                );
              })}
          </StyledMap>
          <Row className="mb-4">
            <Col lg={3}>
              <StyledDistanceFilter>
                <DistanceFilter
                  handleDistanceChange={handleDistanceChange}
                  distance={props.distance}
                  type={props.type}
                />
              </StyledDistanceFilter>
            </Col>
          </Row>
          {props.isLoading ? (
            <ContentWrapper>
              <Spinner size="sm" animation="border" className="ms-2" />
            </ContentWrapper>
          ) : (
            <StyledPointList
              columns={pointsToShow?.length >= 8 ? true : false}
              points={pointsToShow}
            >
              {isPoints &&
                pointsToShow?.map((point: TPoint, idx: number) => {
                  const path = point.url?.includes("locations")
                    ? point.url
                    : "";
                  return (
                    <li
                      className={idx > 9 && viewMore ? "d-none" : ""}
                      key={point.uuid}
                      data-index={idx}
                      id={`point-of-interest-${idx}`}
                      data-position={point.coordinates}
                      onClick={handleSetListPosition}
                    >
                      {point.name}, {point.city}, {point.state},{" "}
                      {`${point.rangeInMilesFromOrigin} miles`}
                      {path && (
                        <a href={path} className="visually-hidden">
                          {point.name}
                        </a>
                      )}
                    </li>
                  );
                })}
              {isMobileOnly && props.points?.length > 10 && (
                <a
                  className="viewMoreLess"
                  id="view-more-button"
                  onClick={handleViewMoreLessPOI}
                >
                  {viewMore ? "View More +" : "View Less -"}
                </a>
              )}
            </StyledPointList>
          )}
          {!props.points?.length && (
            <ContentWrapper>
              <p>
                There are no points of interest within the specified radius.
              </p>
            </ContentWrapper>
          )}
        </div>
      </React.Fragment>
    ) : null;
  }
  return <></>;
};

export default PointsOfInterest;
