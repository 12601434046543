import React, { useState, useEffect } from "react";
import { IHotelRoomsProps, IRoomRates } from "./HotelRoomsProps";
import {
  AccessibleFilterCol,
  Container,
  FilterTabsContainer,
} from "./HotelRoomsHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { navigate } from "gatsby";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import Axios from "axios";
import RoomCard from "../RoomCard/RoomCard";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import {
  searchSelector,
  setSearch,
  setcheckoutState,
} from "../../../redux/slices/Search/search";
import {
  selectHotelRates,
  addRates,
  addRatesASE,
  unlockPricing,
} from "../../../redux/slices/Rate/rate";
import { IUseRoomDetail, useRoomDetail } from "../../../hooks/useRoomDetail";
import {
  setCheckout,
  setPropertyPagePath,
} from "../../../redux/slices/Checkout/checkout";
import {
  buildFetchRatesUrl,
  fetchRoomsAndRatesASE,
} from "../../../services/rates";
import { sortRooms } from "../../../services/helpers";
import { convertArrayToObject } from "../../../utils/helpers";
import {
  addToCartGTMDataLayer,
  addPropertyRoomsGTMDataLayer,
  fireProductDetailEvent,
} from "../../../utils/datalayers";
import UnlockBanner from "../UnlockBanner/UnlockBanner";
import RoomSort from "./RoomSort/RoomSort";
// import OccupancyFilter from "./OccupancyFilter/OccupancyFilter";
import RoomTypeFilter from "./RoomTypeFilter/RoomTypeFilter";
import merge from "lodash/merge";
import PromoFilter from "../PromoFilter/PromoFilter";
import IRoom from "../../../@types/IRoom";
import AccessibleFilter from "./AccessibleFilter/AccessibleFilter";
import useMobileDetect from "../../../hooks/useMobileDetect";
import { Constants } from "../../../@types/Constants";
import { RootState } from "../../../redux/store";
import NotAvailableMessage from "../NotAvailableMessage/NotAvailableMessage";
import OccupancyMessage from "../OccupancyMessage/OccupancyMessage";
import { concat } from "lodash";
import { hotelsSelector } from "../../../redux/slices/Hotel/hotel";
import { Storage } from "../../../utils/storage";
import { guestServiceProvider } from "../../../services/ServiceProvider";
import { useLocation } from "@reach/router";
import { crmProfileSelector } from "../../../redux/slices/Member/member";
import {
  checkUserRedemptionEligiblity,
  getMemberPoints,
} from "../../../services/redemption";
import differenceInDays from "date-fns/differenceInDays";

const HotelRooms = (props: IHotelRoomsProps) => {
  const checkout = useAppSelector((state: RootState) => state.checkout);
  const location = useLocation();
  const search = useAppSelector(searchSelector);
  const crmProfile = useAppSelector(crmProfileSelector);
  const _roomDetail: IUseRoomDetail = {
    hotelCode: props.hotel.crs_code,
    crsName: props.hotel.crs_name,
  };
  const isMobileOnly = useMobileDetect();
  const rates = useAppSelector(selectHotelRates([props.hotel.crs_code]));
  const [rooms, setRooms] = useState(null);
  const [roomRates, setRoomRates] = useState(null);
  const [roomsLoaded, setRoomsLoaded] = useState(false);
  const [loadingRates, setLoadingRates] = useState(false);
  const [updatingRates, setUpdatingRates] = useState(false);
  const [pricingInitialized, setPricingInitialized] = useState(false);
  const [loadingRoomDetail, roomDetail, allRooms] = useRoomDetail(_roomDetail);
  const [roomsToDisplay, setRoomsToDisplay] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortOrderValue, setSortOrderValue] = useState("Lowest Price");
  const [filteredRooms, setFilteredRooms] = useState([]);
  const [filteredRoomsLoaded, setFilteredRoomsLoaded] = useState(false);
  const [filterRoomType, setFilterRoomType] = useState("");
  const [accessibleFilter, setAccessibleFilter] = useState(false);
  const [filterOccupancy, setFilterOccupancy] = useState("");
  const dispatch = useAppDispatch();
  const [showOfferMessage, setShowOfferMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [roomRatesParsed, setRoomRatesParsed] = useState(false);
  const [totalVisibleRoom, setTotalVisibleRoom] = useState(2);
  const [showFilterTabs, setShowFilterTabs] = useState(false);
  const [showRoomTypeFilter, setShowRoomTypeFilter] = useState(false);
  const [isPromoValid, setIsPromoValid] = useState(false);
  const [isGroupCodeValid, setIsGroupCodeValid] = useState(false);
  const hotels = useAppSelector(hotelsSelector);
  const hrDiscountUnlockedInStore = useAppSelector(
    (state) => state.show_special_pricing
  );
  const hrDiscountUnlockedInStorage = Storage.GetLocalStorageValue(
    "HR-discount-unlocked"
  );
  const isLoggedIn = useAppSelector((state) => state.member.isLoggedIn);
  const [fireEvent, setFireEvent] = useState(false);
  const redemptionItem = rates[props.hotel.crs_code as string]?.redemptionItem;
  // let errorTimer: NodeJS.Timeout | null = null;
  const onRoomsLoad = (
    offerAvailable: boolean,
    rate: string,
    discountType: string
  ) => {
    if (!offerAvailable) {
      const errorMsg = (
        <>
          Your {discountType} <strong>{rate}</strong> is unavailable for the
          selected dates. We are showing lowest available rates.
        </>
      );

      setShowOfferMessage(true);
      setMessage(errorMsg);
    }
    const element = document.getElementById("offer-message");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const getHotelSrchPostionForDetail = (hotels) => {
    const hotelList = Object.values(hotels);
    const index = hotelList.findIndex(
      (hotel) => hotel.name === props.hotel.name
    );
    return index === -1 ? 1 : index + 1;
  };

  const getProductForDL = () => {
    let selectedRoom = null;
    let price = null;
    let bestRateCode = null;
    let baseRate = null;
    let isThisHotelPromoValid = null;
    let isPromoValid = null;
    const crs = props.hotel.crs_code;
    if (crs && rates[crs as string]?.Rooms) {
      const parsedRooms = fetchRoomsAndRatesASE(
        rates[crs as string].Rooms,
        search.discount,
        search.promotionCode,
        search.groupCode
      );

      if (parsedRooms !== null) {
        selectedRoom = parsedRooms[0];
        price = parsedRooms[0] ? parsedRooms[0].FromRate : null;
        bestRateCode = parsedRooms[0] ? parsedRooms[0].FromRateCode : null;
        baseRate = parsedRooms[0] ? parsedRooms[0].BaseRate : null;
        if (search.promotionCode || search.groupCode) {
          isThisHotelPromoValid = parsedRooms[0]
            ? parsedRooms[0].FromRatePromotional
            : null;
          if (!isPromoValid) {
            isPromoValid = isThisHotelPromoValid;
          }
        }
      }
    }

    const showUnlockButton =
      selectedRoom?.FromRateType === "member" && !isLoggedIn;
    const discountUnlocked =
      hrDiscountUnlockedInStore || hrDiscountUnlockedInStorage;
    const priceLocked = showUnlockButton && !discountUnlocked;
    const hotelBestPrice = price !== null ? parseFloat(price) : null;
    const hotelPriceForDL = priceLocked
      ? selectedRoom
        ? selectedRoom.BaseRate
        : null
      : hotelBestPrice;
    const hotelRateForDL = priceLocked
      ? selectedRoom
        ? selectedRoom.BaseRateCode
        : null
      : bestRateCode;
    return {
      name: props.hotel.name,
      hotelId: props.hotel.crs_code,
      list: search.searchString,
      category: hotelRateForDL,
      brand: props.hotel.relationships.brand_id.name,
      price: hotelPriceForDL,
      position: getHotelSrchPostionForDetail(hotels),
    };
  };

  // Fetch pricing.

  useEffect(() => {
    if (fireEvent) {
      const resultProductForDetail = getProductForDL();
      fireProductDetailEvent(resultProductForDetail, search.searchString);
    }
  }, [fireEvent]);

  useEffect(() => {
    const source = Axios.CancelToken.source();
    let didCancel = false;
    const abortController = new AbortController();
    const fetchRates = async () => {
      const apiUrl = buildFetchRatesUrl(
        props.hotel.crs_name || "",
        props.hotel.crs_code || "",
        search.checkin,
        search.checkout,
        search.rooms[0].adults,
        search.rooms[0].children,
        search.promotionCode,
        search.groupCode,
        null,
        search.rooms[0].childrenAges,
        search.ratePlanFilterCode,
        search.discount
      );
      await dispatch(
        addRatesASE(
          apiUrl,
          search.rooms[0].adults,
          search.rooms[0].children,
          search.rooms[0].childrenAges,
          props.hotel.crs_code,
          props.hotel.crs_chain_id,
          search.checkin,
          search.checkout,
          "",
          search.promotionCode,
          search.groupCode,
          search.discount || search.ratePlanFilterCode || "Retail",
          abortController
        )
      );
      // await dispatch(addRates(props.hotel.crs_code, apiUrl, source));
    };
    // ASE - Availibilty Call
    (async () => {
      setLoadingRates(true);
      setRoomsLoaded(false);
      setMessage("");
      setIsPromoValid(false);
      setShowOfferMessage(false);
      setFilteredRoomsLoaded(false);
      setFilteredRooms([]);
      setRoomsToDisplay([]);
      await fetchRates();
      if (!didCancel) {
        setFireEvent(true);
        setLoadingRates(false);
        if (!pricingInitialized) {
          setPricingInitialized(true);
        }
      }
    })();
    const intervalId = setInterval(async () => {
      setUpdatingRates(true);
      setShowOfferMessage(false);

      await fetchRates();
      if (!didCancel) {
        setUpdatingRates(false);
      }
    }, 300000);

    return () => {
      clearInterval(intervalId);
      abortController.abort();
      didCancel = true;
      source.cancel("Request cancelled for fetch rates.");
    };
  }, [search]);

  useEffect(() => {
    let didCancel = false;
    if (pricingInitialized) {
      const roomsFetched =
        (rates && rates[props.hotel.crs_code]?.Rooms) || null;
      if (!didCancel) {
        setRooms(roomsFetched);
        setRoomsLoaded(true);
        //Analytics
        //!didCancel && dispatch(setCheckout({...checkout, Step: 'select_room'}))
      }
    }
    return () => {
      didCancel = true;
    };
  }, [pricingInitialized, rates]);

  useEffect(() => {
    if (!loadingRates && roomsLoaded) {
      const roomRatesFetched = fetchRoomsAndRatesASE(
        rooms,
        search.discount,
        search.promotionCode,
        search.groupCode
      );
      const discountInSearch = search.groupCode || search.promotionCode;
      if (discountInSearch) {
        setSortOrder("asc");
        const offerAvailable =
          roomRatesFetched &&
          roomRatesFetched[0] &&
          roomRatesFetched[0].FromRatePromotional;
        const discountType = search.promotionCode ? "Promo code" : "Group code";
        setIsPromoValid(offerAvailable);
        offerAvailable ? setSortOrderValue("Promotional Price") : null;

        if (search.groupCode) {
          setIsGroupCodeValid(offerAvailable);
        }
        onRoomsLoad &&
          onRoomsLoad(offerAvailable, discountInSearch, discountType);
        // !offerAvailable && dispatch(setSearch({...search, ...{discount: null, groupCode: null, promotionCode: null}}));
      } else {
        setSortOrderValue("Lowest Price");
      }
      setRoomRatesParsed(true);
      setRoomRates(roomRatesFetched);
    }
  }, [rooms, roomsLoaded, loadingRates]);

  useEffect(() => {
    if (!loadingRates && roomsLoaded && roomRatesParsed && allRooms) {
      if (roomRates) {
        const ratesObject = convertArrayToObject(roomRates, "code");
        Object.keys(ratesObject).map((key: string) => {
          if (allRooms && allRooms[key]) {
            ratesObject[key].RoomType = ratesObject[key].amenities.includes(
              "Suite"
            )
              ? "Suites"
              : "Rooms";
            // modifying galleryImages in room object according to Image carousel format
            ratesObject[key].galleryImages = allRooms[key].imageUrls.length
              ? allRooms[key].imageUrls.map((image: string) => ({
                  url: image,
                  alt: image,
                }))
              : [];
          }
        });
        merge(ratesObject, allRooms);
        let showTab = false;
        let allRoomTypes =
          ratesObject &&
          Object.keys(ratesObject).map((k: string) => ratesObject[k].RoomType);
        allRoomTypes = allRoomTypes.flat();
        if (
          allRoomTypes.includes(Constants.ROOM_AMINITY_ID) &&
          allRoomTypes.includes(Constants.SUITE_AMINITY_ID)
        ) {
          showTab = true;
        }
        setShowFilterTabs(true);
        setShowRoomTypeFilter(showTab);
        const mergedRoomsArray = Object.values(ratesObject);
        const sortedRooms: Array<IRoom> = sortRooms(mergedRoomsArray);
        setRoomsToDisplay(sortedRooms);
      } else {
        const sortedRooms = sortRooms(Object.values(allRooms));
        setRoomsToDisplay(sortedRooms);
        setShowFilterTabs(false);
      }
    }
  }, [roomRates, allRooms, loadingRates, roomRatesParsed]);

  useEffect(() => {
    if (!loadingRates && roomsLoaded && roomRatesParsed && allRooms) {
      if (roomsToDisplay && roomsToDisplay.length) {
        addPropertyRoomsGTMDataLayer(
          props.hotel,
          roomsToDisplay,
          search,
          checkout
        );
        let filterRoomsByAccessible = roomsToDisplay.filter(
          (room: IRoomRates) => room.accessible === accessibleFilter
        );
        if (!filterRoomsByAccessible.length) {
          filterRoomsByAccessible = roomsToDisplay.filter(
            (room: IRoomRates) =>
              room.Accessible === true || room.name.includes("Accessible")
          );
          setAccessibleFilter(true);
        }
        setFilteredRooms(filterRoomsByAccessible);
        setTimeout(() => {
          setFilteredRoomsLoaded(true);
        }, 5);
      }
    }
  }, [roomsToDisplay, allRooms, loadingRates, roomRatesParsed]);

  useEffect(() => {
    let roomArray = [...roomsToDisplay];
    if (roomArray && roomArray.length) {
      roomArray = roomArray.filter((room: IRoomRates) => {
        return room.quantity > 0 && room.roomRates;
      });

      if (filterRoomType) {
        roomArray = roomArray.filter((room: IRoomRates) => {
          //Handling the case Where the details API response contains rooms which has empty/null amenities or doesn't contain either of rooms or suites as amenity. These rooms will be shown for all Room Type filter options
          return room.RoomType?.length === 0 ||
            room.RoomType?.includes(null) ||
            !["Suites", "Rooms"].some((element) =>
              room.RoomType?.includes(element)
            )
            ? true
            : room.RoomType && room.RoomType.includes(filterRoomType);
        });
      }

      const filterAccessibleRooms = (fieldValue: boolean) =>
        roomArray.filter((room: IRoomRates) => {
          return room.accessible === fieldValue;
        });

      if (!accessibleFilter) {
        const filterByAccessible = filterAccessibleRooms(false);
        roomArray = [...filterByAccessible];
      } else {
        setAccessibleFilter(true);
      }

      if (filterOccupancy) {
        roomArray = roomArray.filter((room: IRoomRates) => {
          return room.occupancy == filterOccupancy;
        });
      }
    }
    // Sort based on selected sort option
    if (sortOrderValue === "Promotional Price") {
      // Separate promoRooms and remainingRooms based on promoCode
      const promoRooms = roomArray.filter(
        (room: IRoomRates) => room.FromRatePromotional
      );
      const remainingRooms = roomArray.filter(
        (room: IRoomRates) => !room.FromRatePromotional
      );
      // Sort promoRooms based on promotional rates (FromRate or BaseRate if available)
      promoRooms.sort((a: IRoomRates, b: IRoomRates) => {
        const aRate =
          a.BaseRate !== null && a.BaseRate !== undefined
            ? a.BaseRate
            : a.FromRate;
        const bRate =
          b.BaseRate !== null && b.BaseRate !== undefined
            ? b.BaseRate
            : b.FromRate;
        return sortOrder === "asc" ? aRate - bRate : bRate - aRate;
      });

      remainingRooms.sort((a: IRoomRates, b: IRoomRates) => {
        const aRate =
          a.BaseRate !== null && a.BaseRate !== undefined
            ? a.BaseRate
            : a.FromRate;
        const bRate =
          b.BaseRate !== null && b.BaseRate !== undefined
            ? b.BaseRate
            : b.FromRate;

        return sortOrder === "asc" ? aRate - bRate : bRate - aRate;
      });

      // Merge promoRooms and remainingRooms back together
      roomArray = [...promoRooms, ...remainingRooms];
    } else if (sortOrderValue === "Lowest Price") {
      // Sort by BaseRate in ascending or descending order
      roomArray.sort((a: IRoomRates, b: IRoomRates) =>
        sortOrder === "asc" ? a.BaseRate - b.BaseRate : b.BaseRate - a.BaseRate
      );
    } else if (sortOrderValue === "Highest Price") {
      // Sort by BaseRate in ascending or descending order
      roomArray.sort((a: IRoomRates, b: IRoomRates) =>
        sortOrder === "asc" ? b.BaseRate - a.BaseRate : a.BaseRate - b.BaseRate
      );
    }
    // Update the rooms state with the sorted rooms
    setFilteredRooms(roomArray);
  }, [
    filterRoomType,
    filterOccupancy,
    sortOrder,
    accessibleFilter,
    roomsToDisplay,
    sortOrderValue,
  ]);

  useEffect(() => {
    dispatch(
      setCheckout({ ...checkout, Step: "select_room", isResubmitted: false })
    );
    dispatch(setPropertyPagePath(location.pathname));

    return () => {
      setShowOfferMessage(false);
    };
  }, []);

  const handleTogleMobileFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleSortChange = (sortOrder: string) => {
    setSortOrder(sortOrder);
  };

  const handleSelectRoom = async (event: React.MouseEvent<HTMLElement>) => {
    const dataset = event.target.dataset;
    const roomCode = dataset.room;
    const isUnlockButton = dataset.isunlockbutton == "true";
    let NonMemberRates = true;
    const room = roomRates.filter(
      (roomRate) => roomRate.RoomCode === roomCode
    )[0];
    //check if the room contains only one rate and is of type member
    if (room.Rates.length === 1 && room.Rates[0].RateType === "member") {
      NonMemberRates = false;
      dispatch(unlockPricing(true));
    }
    const rateCode =
      isUnlockButton && NonMemberRates ? room.BaseRateCode : room.FromRateCode;
    const rate = room.Rates.filter(
      (rateObject) => rateObject.rateCode === rateCode
    )[0];
    const checkoutRooms = [];
    search.rooms.forEach(function (searchRoom, index) {
      const checkoutRoom = {
        ...searchRoom,
        room: index === 0 ? room : null,
        rate: index === 0 ? rate : null,
      };
      checkoutRooms.push(checkoutRoom);
    });
    const roomRenderedPrice = dataset.roomprice;
    addToCartGTMDataLayer(
      props.hotel,
      room,
      rate,
      roomRenderedPrice,
      search.checkin,
      search.checkout,
      search.rooms?.length
    );
    const redemptionRate = rates[props.hotel.crs_code as string];
    dispatch(
      setcheckoutState({
        rooms: checkoutRooms,
        hotel: props.hotel,
        redemptionItem: redemptionRate?.redemptionItem || "",
        hotelLocation: redemptionRate?.hotelLocation || "",
        Brand: redemptionRate?.Brand || "",
      })
    );
    navigate("/checkout", {
      state: { rooms: checkoutRooms, hotel: props.hotel },
      replace: false,
    });
  };

  const getPoints = async (memberId: string) => {
    try {
      const response = await getMemberPoints(memberId);
      const numOfNights = differenceInDays(
        new Date(search.checkout),
        new Date(search.checkin)
      );
      const pointsRequiredPerDay = parseInt(redemptionItem.currencyRequired);
      const isEligible = checkUserRedemptionEligiblity(
        parseInt(response?.memberPointsData?.available),
        pointsRequiredPerDay,
        numOfNights
      );
      const newCheckout = {
        ...checkout,
        isEligible,
        requiredPoints: pointsRequiredPerDay * numOfNights,
      };
      dispatch(setCheckout(newCheckout));
    } catch (error) {
      // Handle error appropriately (e.g., logging or displaying an error message)
    }
  };

  useEffect(() => {
    // Trigger the getPoints function when the member is logged in and the memberId changes
    const memberId = crmProfile?.memberId;
    if (isLoggedIn && memberId) {
      getPoints(memberId);
    }
  }, [isLoggedIn, crmProfile]);

  return (
    <Container>
      <>
        {isMobileOnly ? <hr style={{ marginTop: "20px" }} /> : null}
        {showFilterTabs ? (
          <>
            <Collapse in={openFilter} className="d-lg-block">
              <div
                id="roomFilters"
                className="room-filters-container mb-4"
                style={{
                  backgroundColor: props.background || "#f8f8f8",
                  whiteSpace: "nowrap",
                }}
              >
                <Row className="search-filters g-0" id="search-filters">
                  <Col
                    lg={{ span: 3 }}
                    className={`text-left mt-3 mt-lg-0 ${openFilter && "mb-4"}`}
                  >
                    <RoomSort
                      sortOrder={sortOrder}
                      setSortOrderValue={setSortOrderValue}
                      sortOrderValue={sortOrderValue}
                    />
                  </Col>
                  <Col xs={6} lg={{ span: 2 }} className="my-auto mb-3 mb-lg-0">
                    {/* {isPromoValid ? <PromoFilter /> : null} */}
                  </Col>
                  <FilterTabsContainer>
                    <Row>
                      {showRoomTypeFilter ? (
                        <>
                          <Col lg={6}>
                            <AccessibleFilter
                              accessibleFilter={accessibleFilter}
                              setAccessibleFilter={setAccessibleFilter}
                            />
                          </Col>
                          <Col lg={6} className="d-flex justify-content-end">
                            <RoomTypeFilter
                              setFilter={setFilterRoomType}
                              filterRoomType={filterRoomType}
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col lg={12}>
                            <div className="d-flex justify-content-end">
                              <AccessibleFilter
                                accessibleFilter={accessibleFilter}
                                setAccessibleFilter={setAccessibleFilter}
                              />
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  </FilterTabsContainer>
                </Row>
                <Button
                  className="close-filters w-100 d-lg-none mt-3"
                  variant="secondary"
                  onClick={handleTogleMobileFilter}
                >
                  Close
                </Button>
              </div>
            </Collapse>
            {isMobileOnly ? (
              showRoomTypeFilter ? (
                <>
                  <Row className="g-0">
                    <Col xs={6} className="text-left mt-lg-0">
                      <RoomSort
                        sortOrder={sortOrder}
                        handleSortChange={handleSortChange}
                        setSortOrderValue={setSortOrderValue}
                        sortOrderValue={sortOrderValue}
                      />
                    </Col>
                    {isPromoValid ? (
                      <Col xs={6} className="my-auto mb-3 mb-lg-0">
                        {/* <PromoFilter /> */}
                      </Col>
                    ) : null}
                  </Row>
                  <hr style={{ marginTop: "20px" }} />
                  <Row className="mb-4">
                    <Col lg={6} xs={7}>
                      <AccessibleFilter
                        accessibleFilter={accessibleFilter}
                        setAccessibleFilter={setAccessibleFilter}
                      />
                    </Col>
                    <Col lg={6} xs={5} className="text-right">
                      <RoomTypeFilter
                        setFilter={setFilterRoomType}
                        filterRoomType={filterRoomType}
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  {isPromoValid ? (
                    <Row className="g-0">
                      <Col xs={6} className="my-auto mb-3 mb-lg-0">
                        {/* <PromoFilter /> */}
                      </Col>
                      <hr style={{ marginTop: "10px" }} />
                    </Row>
                  ) : null}
                  <Row className="mb-4">
                    <Col xs={6} style={{ width: "auto !important" }}>
                      <RoomSort
                        sortOrder={sortOrder}
                        handleSortChange={handleSortChange}
                        setSortOrderValue={setSortOrderValue}
                        sortOrderValue={sortOrderValue}
                      />
                    </Col>

                    <AccessibleFilterCol>
                      <AccessibleFilter
                        accessibleFilter={accessibleFilter}
                        setAccessibleFilter={setAccessibleFilter}
                      />
                    </AccessibleFilterCol>
                  </Row>
                </>
              )
            ) : null}
          </>
        ) : null}
        {loadingRates ||
        loadingRoomDetail ||
        !roomsLoaded ||
        !roomRatesParsed ? (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        ) : filteredRoomsLoaded ? (
          filteredRooms && filteredRooms.length ? (
            <div className="hotel-rooms-container">
              {props.unlockBannerShow ? <UnlockBanner /> : null}
              <OccupancyMessage
                page="selectroom"
                crsCode={props.hotel.crs_code || ""}
                roomIndex={0}
              />
              {showOfferMessage && message && !search.discount && (
                <>
                  <a
                    className="anchor-target visually-hidden"
                    id="offer-message"
                    aria-hidden={true}
                  />
                  <Row className="px-lg-5">
                    <Col>
                      <Alert
                        variant="danger"
                        className="text-center"
                        onClose={() => setShowOfferMessage(false)}
                        dismissible
                      >
                        {message}
                      </Alert>
                    </Col>
                  </Row>
                </>
              )}
              <Row lg={3} xs={1}>
                {isMobileOnly
                  ? filteredRooms
                      .slice(0, totalVisibleRoom)
                      .map((room, idx) => {
                        return (
                          <Col
                            key={`${idx}-hr-${room.RoomCode}`}
                            className="mb-4"
                          >
                            <RoomCard
                              room={room}
                              selectRoom={handleSelectRoom}
                              loadingRates={updatingRates}
                              isGroupCodeValid={isGroupCodeValid}
                              hotel={props.hotel}
                              hotelInfoUrgBgColor={props.hotelInfoUrgBgColor}
                              hotelInfoUrgFontColor={
                                props.hotelInfoUrgFontColor
                              }
                              redemptionItem={redemptionItem}
                            />
                          </Col>
                        );
                      })
                  : filteredRooms.map((room, idx) => {
                      return (
                        <Col
                          key={`${idx}-hr-${room.RoomCode}`}
                          className="mb-4"
                        >
                          <RoomCard
                            room={room}
                            selectRoom={handleSelectRoom}
                            loadingRates={updatingRates}
                            isGroupCodeValid={isGroupCodeValid}
                            hotel={props.hotel}
                            hotelInfoUrgBgColor={props.hotelInfoUrgBgColor}
                            hotelInfoUrgFontColor={props.hotelInfoUrgFontColor}
                            roomCardId={idx + 1}
                            redemptionItem={redemptionItem}
                          />
                        </Col>
                      );
                    })}
                {isMobileOnly &&
                filteredRooms.length &&
                totalVisibleRoom < filteredRooms.length ? (
                  <Col className="mb-4">
                    <Button
                      className="w-100 text-uppercase show-all-select-room"
                      id="show-all-rooms-button"
                      size="lg"
                      onClick={() => setTotalVisibleRoom(filteredRooms.length)}
                    >
                      Show all {filteredRooms.length} Results &nbsp;
                      <FontAwesomeIcon
                        icon="chevron-down"
                        className="drop-down-icon"
                      />
                    </Button>
                  </Col>
                ) : null}
              </Row>
            </div>
          ) : (
            <Row className={`${!showFilterTabs ? "mt-3" : ""}`}>
              <Col>
                <NotAvailableMessage />
              </Col>
            </Row>
          )
        ) : null}
      </>
    </Container>
  );
};

export default HotelRooms;
