import * as React from "react";
import { styledMap, styledPointList,column1,column2,styledDistanceFilter,contentWrapper } from "./PointsOfInterest.module.scss";
import { MapContainer } from "react-leaflet";
import { isBrowser } from "../../../utils/helpers";
import { TPoint } from "./PointsOfInterestProps";

export const StyledMap = (props: any) => {
    return (isBrowser ? <MapContainer {...props} className={`${styledMap} mb-3 `}  id="point-of-interest-map">{props.children}</MapContainer>: null);
};

export const StyledPointList = (props: {columns:boolean,children:React.ReactNode,points:Array<TPoint>}) => {
    return (<div className={`${styledPointList} ${props.columns? column2:column1} ${props.points?.length !==0 ?"d-block my-2" : "d-none"}`}><ol>{props.children}</ol></div>);
};

export const StyledDistanceFilter = (props: {children:React.ReactNode}) => {
    return ( <div  className={styledDistanceFilter}>{props.children}</div>);
};
export const ContentWrapper = (props: {children:React.ReactNode}) => {
    return ( <div  className={contentWrapper}>{props.children}</div>);
};



